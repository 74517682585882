import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { ExecutionResult } from 'graphql'
import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { Button, Spacer } from '@client/components/atoms/index'
import { StoreStickyButtonContainer } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SaveUserMenuMutation, UserCartDocument, useSaveUserMenuMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { useEvents } from '@contexts/GTMProvider'

const StickyButtonContainer = styled.div<{ $isMobile: boolean }>`
 ${(props): CSS => StoreStickyButtonContainer(props.$isMobile)};
`

export interface StoreStickyButtonProps {
  isMobile?: boolean
  className?: string
}

interface StoreStickyButtonState {
  sourceIsPersonalizedHomepage: boolean
  loading: boolean
}

const DEFAULT_STATE: StoreStickyButtonState = {
  sourceIsPersonalizedHomepage: false,
  loading: false,
}

export function StoreStickyButton({ isMobile, className }: StoreStickyButtonProps): JSX.Element {

  const [state, setState] = useState<StoreStickyButtonState>({ ...DEFAULT_STATE })
  const location = useLocation()
  const navigate = useNavigate()
  const config = useConfig()
  const [saveMenu] = useSaveUserMenuMutation()
  const { addToast } = useToasts()
  const { data: cartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const checkoutStatus = cartData?.currentUser?.checkoutStatus
  const hasOnDemandItems = cartData?.currentUser?.activeCart?.totalNonSubscriptionItems > 0
  const event = useEvents()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  let checkoutDisabled = true

  if (checkoutStatus?.hasActiveSubscription && (checkoutStatus?.hasConfiguredBox || hasOnDemandItems) && !checkoutStatus?.subscriptionIsSaved) {
    checkoutDisabled = false
  } else if ((checkoutStatus?.hasPausedSubscription || !checkoutStatus?.hasActiveSubscription) && (checkoutStatus?.hasConfiguredBox || hasOnDemandItems)) {
    checkoutDisabled = false
  }

  const _saveUserMenu = async (id: string): Promise<void> => {

    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))

    try {
      const result: ExecutionResult<SaveUserMenuMutation> = await saveMenu({
        variables: {
          id,
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      const discounts = userCartData?.currentUser?.activeCart?.reductions
      let discountTitles = ''
      let discountValue = 0
  
      for (let i = 0; i < discounts.length; i++) {
        discountTitles = discountTitles + discounts[i].title
        discountValue = discountValue + discounts[i].value
      }
      const deliveryFee = userCartData?.currentUser?.activeCart?.additions?.find((addition) => addition.title === 'Delivery Fee')?.value 
      const cartItemPrice = userCartData?.currentUser?.activeCart?.grandTotal
      const totalPrice = deliveryFee + cartItemPrice
      CheckoutPlugin.shared().setCartErrors(client, [...result.data.userMenuSave.errors])
      event.hasSavedCart( userCartData?.currentUser?.activeCart?.cartItems, totalPrice, discountTitles, discountValue, userDetailsData?.currentUser?.id)

      addToast('Your cart has been saved!', {
        appearance: 'success',
        autoDismiss: true,
      })

      navigate('/')
    } catch (e) {
      addToast(e.message, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }

    setState((prevState) => update(prevState, {
      loading: { $set: false },
    }))

  }

  useEffect(() => {
    if (location && location.search.includes('source=personalizedHome')) {
      setState((prevState) => update(prevState, {
        sourceIsPersonalizedHomepage: { $set: true },
      }))
    }
  }, [location.pathname])

  const _handleSaveCart = () => {
    _saveUserMenu(cartData?.currentUser?.activeMenu?.id)
  }

  const _handleBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <If condition={state.sourceIsPersonalizedHomepage}>
      <StickyButtonContainer className={className} $isMobile={isMobile}>
        <Button fullWidth color='grey' title='BACK' onClick={_handleBackClick} />
        <Spacer universal='16px' variant='horizontal' />
        <Button fullWidth color='green' loading={state.loading} title='SAVE CART' disabled={checkoutDisabled} onClick={_handleSaveCart} />
      </StickyButtonContainer>
      <Spacer desktop='16px' />
    </If>
  )
}
